.gallery{
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.gallery img{
    width: 33%;
}

#baynk1{
    width: 100%;
}

/* 

#august1
{
    width: 27%;
    top: 118vw;
    left: 69.2vw; 
}
#august2
{
    width: 28%;
    top: 139.5vw;
    left: 39vw;
}
#august3
{
    width: 28%;
    top: 118vw;
    left: 39vw; 
}
#baynk1
{
    width: 95%; 
    top: 20vw;
    left: 2vw;
}
#baynk2
{
    width: 25%; 
    left: 71.1vw;
    top: 323vw;
}
#baynk3
{
    width: 30%; 
}
#baynk4
{
    width: 30%; 
}
#goody1
{
    width: 46%;
    left: 2vw;
    top: 85vw; 
}
#jakubi1
{
    width: 67.5%;
    left: 2vw;
    top: 225vw; 
}
#jakubi2
{
    width: 30%; 
}
#jesse1
{
    width: 35%;
    left: 2vw;
    top: 327.5vw; 
}
#limbo1
{
    width: 35%; 
    left: 2vw;
    top: 272.5vw;
}
#limbo2
{
    width: 30%;
    top: 272.5vw;
    left: 39vw; 
}
#mch1
{
    width: 25%;
    left: 71.1vw;
    top: 242.8vw; 
}
#minimansions1
{
    width: 30%;
    left: 39vw;
    top: 295vw; 
}
#minimansions2
{
    width: 30%; 
}
#minimansions3
{
    width: 30%; 
}
#mnek1
{
    width: 25%;
    left: 71.1vw;
    top: 283vw; 
}
#mnek2
{
    width: 35%;
    left: 2vw;
    top: 199vw; 
}
#neonindian1
{
    width: 30%;
    left: 39vw;
    top: 317.6vw; 
}
#neonindian2
{
    width: 30%;
    left: 39vw;
    top: 202.3vw; 
}
#neonindian3
{
    width: 35%;
    left: 2vw;
    top: 173vw; 
}
#neonindian4
{
    width: 30%; 
}
#nombe1
{
    width: 46%;
    top: 85vw;
    left: 50.4vw; 
}
#nombe2
{
    width: 57.6%;
    left: 39vw;
    top: 161vw;
}
#nombe3
{
    width: 30%;
}
#ritchie2
{
    width: 30%; 
}
#ritchie1
{
    width: 25%;
    left: 71.1vw;
    top: 202.3vw; 
}
#ritchie3
{
    width: 30%; 
}
#shae1
{
    width: 30%; 
}
#shae2
{
    width: 35%; 
    left: 2vw;
    top: 118vw;
}
#shae3
{
    width: 30%; 
} */
