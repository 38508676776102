

.title-text{
    flex-direction: column;
    align-items: center;
}

.rose-background{
    background-color: #1d1d1d;
}

/* .t_over:hover ~ .rose-background{
    background-color: #f5c2e5;
} */

.pageTitle{
    width: 250px;
    padding-bottom: 20px;
}

.pageLight{
    height: 100%;
}

@keyframes glow-pink {
    0% {
        box-shadow: 0px -10px 80px 20px #ffa3e2,
        inset 0 0 3px 3px #ffa3e2;
    }

    50% {
        box-shadow: 0 0 80px 35px #ffa3e2,
        inset 0 0 3px 1px #ffa3e2;
    }

    100% {
        box-shadow: 0px -10px 80px 20px #ffa3e2,
        inset 0 0 3px 3px #ffa3e2;
    }
}

.pink{
    box-shadow: 
        0 0 50px 10px #ffa3e2,
        inset 0 0 5px 1px #ffa3e2;
        animation: 10s ease-in-out infinite glow-pink;

}


.t_over:nth-child(1):hover ~ .rose-background{ box-shadow: inset 4px 4px 8px 2px #ffa3e2, -2px -2px 8px 0px #ffa3e2;}
.t_over:nth-child(2):hover ~ .rose-background{ box-shadow: inset 0px 4px 8px 2px #ffa3e2, 0px -2px 8px 0px #ffa3e2;}
.t_over:nth-child(3):hover ~ .rose-background{ box-shadow: inset -4px 4px 8px 2px #ffa3e2, 2px -2px 8px 0px #ffa3e2;}
.t_over:nth-child(4):hover ~ .rose-background{ box-shadow: inset 4px 0px 8px 2px #ffa3e2, -2px 0px 8px 0px #ffa3e2;}
.t_over:nth-child(5):hover ~ .rose-background{ box-shadow: inset 0px 0px 8px 0px #ffa3e2, 0px 0px 4px 0px #ffa3e2;}
.t_over:nth-child(6):hover ~ .rose-background{ box-shadow: inset -4px 0px 8px 2px #ffa3e2, 2px 0px 8px 0px #ffa3e2;}
.t_over:nth-child(7):hover ~ .rose-background{ box-shadow: inset 4px -4px 8px 2px #ffa3e2, -2px 2px 8px 0px #ffa3e2;}
.t_over:nth-child(8):hover ~ .rose-background{ box-shadow: inset 0px -4px 8px 2px #ffa3e2, 0px 2px 8px 0px #ffa3e2;}
.t_over:nth-child(9):hover ~ .rose-background{ box-shadow: inset -4px -4px 8px 2px #ffa3e2, 2px 2px 8px 0px #ffa3e2;}

