.thinkingCapPage{

    font-family: "MontserratRegular";
     color: white;
     width: 100%;
     font-size: 18px;
     text-align: left;
     display: inline-flex;
     position: relative;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     padding: 0% 20%;
     margin-top: 50px;
 }

.img-thinkingcap{
    width: 50%;
    padding: 2%;
}

.centered{
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 750px) {

    .img-thinkingcap{
        width: 100%;
    }

    .sec-split{
        display: block;
    }

}