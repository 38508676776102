

.title-text{
    flex-direction: column;
    align-items: center;
}

.purple-background{
    background-color: #1d1d1d;
}

.t_over:nth-child(1):hover ~ .purple-background{ box-shadow: inset 4px 4px 8px 2px #bb6cf9, -2px -2px 8px 0px #bb6cf9;}
.t_over:nth-child(2):hover ~ .purple-background{ box-shadow: inset 0px 4px 8px 2px #bb6cf9, 0px -2px 8px 0px #bb6cf9;}
.t_over:nth-child(3):hover ~ .purple-background{ box-shadow: inset -4px 4px 8px 2px #bb6cf9, 2px -2px 8px 0px #bb6cf9;}
.t_over:nth-child(4):hover ~ .purple-background{ box-shadow: inset 4px 0px 8px 2px #bb6cf9, -2px 0px 8px 0px #bb6cf9;}
.t_over:nth-child(5):hover ~ .purple-background{ box-shadow: inset 0px 0px 8px 0px #bb6cf9, 0px 0px 4px 0px #bb6cf9;}
.t_over:nth-child(6):hover ~ .purple-background{ box-shadow: inset -4px 0px 8px 2px #bb6cf9, 2px 0px 8px 0px #bb6cf9;}
.t_over:nth-child(7):hover ~ .purple-background{ box-shadow: inset 4px -4px 8px 2px #bb6cf9, -2px 2px 8px 0px #bb6cf9;}
.t_over:nth-child(8):hover ~ .purple-background{ box-shadow: inset 0px -4px 8px 2px #bb6cf9, 0px 2px 8px 0px #bb6cf9;}
.t_over:nth-child(9):hover ~ .purple-background{ box-shadow: inset -4px -4px 8px 2px #bb6cf9, 2px 2px 8px 0px #bb6cf9;}

.pageTitle{
    width: 250px;

    padding-bottom: 20px;
}

.pageLight{
    height: 100%;
}