.full-width{
    width: 100%;
}

#rsp1{
    width: 100%;
}

#rsp12{
    width: 100%;
}

#rsp13{
    width: 67%;
}

#malav4{
    width: 66%;
}

#rt1{
    width: 100%;
}

#pc2{
    width: 100%;
}

#pc9{
    width: 100%;
}

#polartec9{
    width: 100%;
}

