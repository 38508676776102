.lightenPage{

    font-family: "MontserratRegular";
     color: white;
     width: 100%;
     font-size: 18px;
     text-align: left;
     display: inline-flex;
     position: relative;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     margin-top: 50px;
     padding: 0% 20%;
 }

.img-inbox{
    width: 80%;
}

.sec-split{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

#logo{
    width: 55%;
}

@media only screen and (max-width: 750px) {

    .img-inbox{
        width: 100%;
    }

    .sec-split{
        display: block;
    }

    #logo{
        width: 100%;
    }
}