.design{
    flex-direction: column;
    top: 50px;
}

.title-text{
    flex-direction: column;
    align-items: center;
}

.ui-ux-background,
.brand-background,
.product-background,
.photo-background,
.art-background,
.fashion-background,
.illustration-background {
    background-color: #1d1d1d;
    border-radius: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card {
    /* width: 350px;
    height: 650px; */
    padding: 1.5rem;
}

.card-title {
    margin-bottom: 1rem;
}

.card-title p {
    font-size: 24px;
    line-height: 1.4;
}

.card-image {
    /* width: 100%; */
    /* height: auto; */
    border-radius: 10px;
    margin-bottom: 1rem;
    object-fit: cover;
    aspect-ratio: 16/9;
}

.card-text {
    display: flex;
    gap: 0.4rem;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.card-text *,
.card-text *:hover,
.card-text *:active,
.card-text *:focus {
    box-shadow: none !important;
}

.card-text span {
    font-size: 12px;
    padding: 0.35rem 0.75rem;
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 0.75px;
    color: white;
    cursor: default;
    border: none;
}

.card-text span.ui-ux { background: rgba(69, 163, 255, 0.85); }
.card-text span.brand { background: #29d772e0; }
.card-text span.product { background: rgba(255, 107, 156, 0.85); }
.card-text span.photo { background: rgba(255, 215, 0, 0.85); }
.card-text span.art { background: rgba(255, 77, 77, 0.85); }
.card-text span.fashion { background: rgba(153, 50, 204, 0.85); }
.card-text span.illustration { background: rgba(255, 140, 0, 0.85); }

/* .t_over:hover ~ .blue-background{
    background-color: #a1ddff;
} */

.pageTitle{
    width: 250px;
    padding-bottom: 20px;
}

.pageLight{
    height: 100%;
}

/* UI/UX (Blue) hover effects */
.t_over:nth-child(1):hover ~ .ui-ux-background { box-shadow: inset 4px 4px 8px 2px #45a3ff, -2px -2px 8px 0px #45a3ff; }
.t_over:nth-child(2):hover ~ .ui-ux-background { box-shadow: inset 0px 4px 8px 2px #45a3ff, 0px -2px 8px 0px #45a3ff; }
.t_over:nth-child(3):hover ~ .ui-ux-background { box-shadow: inset -4px 4px 8px 2px #45a3ff, 2px -2px 8px 0px #45a3ff; }
.t_over:nth-child(4):hover ~ .ui-ux-background { box-shadow: inset 4px 0px 8px 2px #45a3ff, -2px 0px 8px 0px #45a3ff; }
.t_over:nth-child(5):hover ~ .ui-ux-background { box-shadow: inset 0px 0px 8px 0px #45a3ff, 0px 0px 4px 0px #45a3ff; }
.t_over:nth-child(6):hover ~ .ui-ux-background { box-shadow: inset -4px 0px 8px 2px #45a3ff, 2px 0px 8px 0px #45a3ff; }
.t_over:nth-child(7):hover ~ .ui-ux-background { box-shadow: inset 4px -4px 8px 2px #45a3ff, -2px 2px 8px 0px #45a3ff; }
.t_over:nth-child(8):hover ~ .ui-ux-background { box-shadow: inset 0px -4px 8px 2px #45a3ff, 0px 2px 8px 0px #45a3ff; }
.t_over:nth-child(9):hover ~ .ui-ux-background { box-shadow: inset -4px -4px 8px 2px #45a3ff, 2px 2px 8px 0px #45a3ff; }

/* Brand (Purple) hover effects */
.t_over:nth-child(1):hover ~ .brand-background { box-shadow: inset 4px 4px 8px 2px #29d772, -2px -2px 8px 0px #29d772; }
.t_over:nth-child(2):hover ~ .brand-background { box-shadow: inset 0px 4px 8px 2px #29d772, 0px -2px 8px 0px #29d772; }
.t_over:nth-child(3):hover ~ .brand-background { box-shadow: inset -4px 4px 8px 2px #29d772, 2px -2px 8px 0px #29d772; }
.t_over:nth-child(4):hover ~ .brand-background { box-shadow: inset 4px 0px 8px 2px #29d772, -2px 0px 8px 0px #29d772; }
.t_over:nth-child(5):hover ~ .brand-background { box-shadow: inset 0px 0px 8px 0px #29d772, 0px 0px 4px 0px #29d772; }
.t_over:nth-child(6):hover ~ .brand-background { box-shadow: inset -4px 0px 8px 2px #29d772, 2px 0px 8px 0px #29d772; }
.t_over:nth-child(7):hover ~ .brand-background { box-shadow: inset 4px -4px 8px 2px #29d772, -2px 2px 8px 0px #29d772; }
.t_over:nth-child(8):hover ~ .brand-background { box-shadow: inset 0px -4px 8px 2px #29d772, 0px 2px 8px 0px #29d772; }
.t_over:nth-child(9):hover ~ .brand-background { box-shadow: inset -4px -4px 8px 2px #29d772, 2px 2px 8px 0px #29d772; }

/* Product (Rose) hover effects */
.t_over:nth-child(1):hover ~ .product-background { box-shadow: inset 4px 4px 8px 2px #ff6b9c, -2px -2px 8px 0px #ff6b9c; }
.t_over:nth-child(2):hover ~ .product-background { box-shadow: inset 0px 4px 8px 2px #ff6b9c, 0px -2px 8px 0px #ff6b9c; }
.t_over:nth-child(3):hover ~ .product-background { box-shadow: inset -4px 4px 8px 2px #ff6b9c, 2px -2px 8px 0px #ff6b9c; }
.t_over:nth-child(4):hover ~ .product-background { box-shadow: inset 4px 0px 8px 2px #ff6b9c, -2px 0px 8px 0px #ff6b9c; }
.t_over:nth-child(5):hover ~ .product-background { box-shadow: inset 0px 0px 8px 0px #ff6b9c, 0px 0px 4px 0px #ff6b9c; }
.t_over:nth-child(6):hover ~ .product-background { box-shadow: inset -4px 0px 8px 2px #ff6b9c, 2px 0px 8px 0px #ff6b9c; }
.t_over:nth-child(7):hover ~ .product-background { box-shadow: inset 4px -4px 8px 2px #ff6b9c, -2px 2px 8px 0px #ff6b9c; }
.t_over:nth-child(8):hover ~ .product-background { box-shadow: inset 0px -4px 8px 2px #ff6b9c, 0px 2px 8px 0px #ff6b9c; }
.t_over:nth-child(9):hover ~ .product-background { box-shadow: inset -4px -4px 8px 2px #ff6b9c, 2px 2px 8px 0px #ff6b9c; }

/* Photo (Yellow) hover effects */
.t_over:nth-child(1):hover ~ .photo-background { box-shadow: inset 4px 4px 8px 2px #ffd700, -2px -2px 8px 0px #ffd700; }
.t_over:nth-child(2):hover ~ .photo-background { box-shadow: inset 0px 4px 8px 2px #ffd700, 0px -2px 8px 0px #ffd700; }
.t_over:nth-child(3):hover ~ .photo-background { box-shadow: inset -4px 4px 8px 2px #ffd700, 2px -2px 8px 0px #ffd700; }
.t_over:nth-child(4):hover ~ .photo-background { box-shadow: inset 4px 0px 8px 2px #ffd700, -2px 0px 8px 0px #ffd700; }
.t_over:nth-child(5):hover ~ .photo-background { box-shadow: inset 0px 0px 8px 0px #ffd700, 0px 0px 4px 0px #ffd700; }
.t_over:nth-child(6):hover ~ .photo-background { box-shadow: inset -4px 0px 8px 2px #ffd700, 2px 0px 8px 0px #ffd700; }
.t_over:nth-child(7):hover ~ .photo-background { box-shadow: inset 4px -4px 8px 2px #ffd700, -2px 2px 8px 0px #ffd700; }
.t_over:nth-child(8):hover ~ .photo-background { box-shadow: inset 0px -4px 8px 2px #ffd700, 0px 2px 8px 0px #ffd700; }
.t_over:nth-child(9):hover ~ .photo-background { box-shadow: inset -4px -4px 8px 2px #ffd700, 2px 2px 8px 0px #ffd700; }

/* Art (Red) hover effects */
.t_over:nth-child(1):hover ~ .art-background { box-shadow: inset 4px 4px 8px 2px #ff4d4d, -2px -2px 8px 0px #ff4d4d; }
.t_over:nth-child(2):hover ~ .art-background { box-shadow: inset 0px 4px 8px 2px #ff4d4d, 0px -2px 8px 0px #ff4d4d; }
.t_over:nth-child(3):hover ~ .art-background { box-shadow: inset -4px 4px 8px 2px #ff4d4d, 2px -2px 8px 0px #ff4d4d; }
.t_over:nth-child(4):hover ~ .art-background { box-shadow: inset 4px 0px 8px 2px #ff4d4d, -2px 0px 8px 0px #ff4d4d; }
.t_over:nth-child(5):hover ~ .art-background { box-shadow: inset 0px 0px 8px 0px #ff4d4d, 0px 0px 4px 0px #ff4d4d; }
.t_over:nth-child(6):hover ~ .art-background { box-shadow: inset -4px 0px 8px 2px #ff4d4d, 2px 0px 8px 0px #ff4d4d; }
.t_over:nth-child(7):hover ~ .art-background { box-shadow: inset 4px -4px 8px 2px #ff4d4d, -2px 2px 8px 0px #ff4d4d; }
.t_over:nth-child(8):hover ~ .art-background { box-shadow: inset 0px -4px 8px 2px #ff4d4d, 0px 2px 8px 0px #ff4d4d; }
.t_over:nth-child(9):hover ~ .art-background { box-shadow: inset -4px -4px 8px 2px #ff4d4d, 2px 2px 8px 0px #ff4d4d; }

/* Fashion (Purple) hover effects */
.t_over:nth-child(1):hover ~ .fashion-background { box-shadow: inset 4px 4px 8px 2px #9932cc, -2px -2px 8px 0px #9932cc; }
.t_over:nth-child(2):hover ~ .fashion-background { box-shadow: inset 0px 4px 8px 2px #9932cc, 0px -2px 8px 0px #9932cc; }
.t_over:nth-child(3):hover ~ .fashion-background { box-shadow: inset -4px 4px 8px 2px #9932cc, 2px -2px 8px 0px #9932cc; }
.t_over:nth-child(4):hover ~ .fashion-background { box-shadow: inset 4px 0px 8px 2px #9932cc, -2px 0px 8px 0px #9932cc; }
.t_over:nth-child(5):hover ~ .fashion-background { box-shadow: inset 0px 0px 8px 0px #9932cc, 0px 0px 4px 0px #9932cc; }
.t_over:nth-child(6):hover ~ .fashion-background { box-shadow: inset -4px 0px 8px 2px #9932cc, 2px 0px 8px 0px #9932cc; }
.t_over:nth-child(7):hover ~ .fashion-background { box-shadow: inset 4px -4px 8px 2px #9932cc, -2px 2px 8px 0px #9932cc; }
.t_over:nth-child(8):hover ~ .fashion-background { box-shadow: inset 0px -4px 8px 2px #9932cc, 0px 2px 8px 0px #9932cc; }
.t_over:nth-child(9):hover ~ .fashion-background { box-shadow: inset -4px -4px 8px 2px #9932cc, 2px 2px 8px 0px #9932cc; }

/* Illustration (Orange) hover effects */
.t_over:nth-child(1):hover ~ .illustration-background { box-shadow: inset 4px 4px 8px 2px #ff8c00, -2px -2px 8px 0px #ff8c00; }
.t_over:nth-child(2):hover ~ .illustration-background { box-shadow: inset 0px 4px 8px 2px #ff8c00, 0px -2px 8px 0px #ff8c00; }
.t_over:nth-child(3):hover ~ .illustration-background { box-shadow: inset -4px 4px 8px 2px #ff8c00, 2px -2px 8px 0px #ff8c00; }
.t_over:nth-child(4):hover ~ .illustration-background { box-shadow: inset 4px 0px 8px 2px #ff8c00, -2px 0px 8px 0px #ff8c00; }
.t_over:nth-child(5):hover ~ .illustration-background { box-shadow: inset 0px 0px 8px 0px #ff8c00, 0px 0px 4px 0px #ff8c00; }
.t_over:nth-child(6):hover ~ .illustration-background { box-shadow: inset -4px 0px 8px 2px #ff8c00, 2px 0px 8px 0px #ff8c00; }
.t_over:nth-child(7):hover ~ .illustration-background { box-shadow: inset 4px -4px 8px 2px #ff8c00, -2px 2px 8px 0px #ff8c00; }
.t_over:nth-child(8):hover ~ .illustration-background { box-shadow: inset 0px -4px 8px 2px #ff8c00, 0px 2px 8px 0px #ff8c00; }
.t_over:nth-child(9):hover ~ .illustration-background { box-shadow: inset -4px -4px 8px 2px #ff8c00, 2px 2px 8px 0px #ff8c00; }

@media only screen and (max-width:750px) {
    
    .design{
        top: 50px;
    }

    .title-text{
        width: 80%;
        left: 15%;
    }
}