.havitPage{

    font-family: "MontserratRegular";
     color: white;
     width: 100%;
     font-size: 18px;
     text-align: left;
     display: inline-flex;
     position: relative;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     margin-top: 50px;
     padding: 0% 20%;
 }

.img-inbox{
    width: 80%;
}

.centered{
    display: flex;
    justify-content: center;
}

.sec-column-right{
    width: 50%;
    padding-right: 10%;
}

.traits-list{
    display: flex;
    font-size: small;
    background-color: white;
    color: black;
    width: 100%;
}

.trait{
    padding: 10px;
}

#havitHome{
    width: 30%;
    /* padding-left: 50px; */
}

#havitSocial{
    width: 50%;
}

#spiritInspo{
    width: 50%;
}

#spiritHome{
    width: 50%;
}

#spiritForm{
    width: 30%;
}

#spiritSkills{
    width: 100%;
}

@media only screen and (max-width: 750px) {

    .img-inbox{
        width: 100%;
    }

    .havitPage{
        width: fit-content;
    }

    .sec-column-right{
        width: 85%;
        padding-right: none;
    }

    #havitHome{
        width: 100%;
    }

    #spiritHome{
        width: 100%;
    }

    #spiritInspo{
        width: 100%;
    }

    #havitSocial{
        width: 100%;
    }

    .traits-list{
        flex-direction: column;
    }

    #havitSkills{
        width: 100%;
    }

    #spiritSkills{
        width: 100%;
    }

    #spiritForm{
        width: 80%;
    }

}