@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;700&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 100px;
  padding-top: 100px;
  text-align: center;
  color: antiquewhite;
}

body {
  font-family: "montserrat";
  line-height: 1.4;
}

a {
  color: #ffffff;
  text-decoration: none;
}

.container {
  padding: 0 1rem;
}

.btn {
  display: inline-block;
  border: none;
  background: #555555;
  color: #ffffff;
  padding: 7px 20px;
  cursor: pointer;
}

.btn:hover {
  background: #666666;
}

