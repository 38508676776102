
.title-text{
    flex-direction: column;
    align-items: center;
}

.cyan-background{
    background-color: #1d1d1d;
}

.t_over:hover ~ .cyan-background{
    background-color: #aae7df;
}

.pageTitle{
    width: 250px;
    padding-bottom: 20px;
}

.pageLight{
    height: 100%;
}