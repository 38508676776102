
.title-text{
    flex-direction: column;
    align-items: center;
}

.light-background{
    background-color: #1d1d1d;
}

.t_over:nth-child(1):hover ~ .light-background{ box-shadow: inset 4px 4px 8px 2px #f1e5ae, -2px -2px 8px 0px #f1e5ae;}
.t_over:nth-child(2):hover ~ .light-background{ box-shadow: inset 0px 4px 8px 2px #f1e5ae, 0px -2px 8px 0px #f1e5ae;}
.t_over:nth-child(3):hover ~ .light-background{ box-shadow: inset -4px 4px 8px 2px #f1e5ae, 2px -2px 8px 0px #f1e5ae;}
.t_over:nth-child(4):hover ~ .light-background{ box-shadow: inset 4px 0px 8px 2px #f1e5ae, -2px 0px 8px 0px #f1e5ae;}
.t_over:nth-child(5):hover ~ .light-background{ box-shadow: inset 0px 0px 8px 0px #f1e5ae, 0px 0px 4px 0px #f1e5ae;}
.t_over:nth-child(6):hover ~ .light-background{ box-shadow: inset -4px 0px 8px 2px #f1e5ae, 2px 0px 8px 0px #f1e5ae;}
.t_over:nth-child(7):hover ~ .light-background{ box-shadow: inset 4px -4px 8px 2px #f1e5ae, -2px 2px 8px 0px #f1e5ae;}
.t_over:nth-child(8):hover ~ .light-background{ box-shadow: inset 0px -4px 8px 2px #f1e5ae, 0px 2px 8px 0px #f1e5ae;}
.t_over:nth-child(9):hover ~ .light-background{ box-shadow: inset -4px -4px 8px 2px #f1e5ae, 2px 2px 8px 0px #f1e5ae;}

.pageTitle{
    width: 250px;
    padding-bottom: 20px;
}

.pageLight{
    height: 100%;
}

@keyframes glow-photo {
    0% {
        box-shadow: 0px -10px 80px 20px #eee4b9,
        inset 0 0 3px 3px #fdcf00;
    }

    50% {
        box-shadow: 0 0 80px 35px #eee4b9,
        inset 0 0 3px 1px #fdcf00;
    }

    100% {
        box-shadow: 0px -10px 80px 20px #eee4b9,
        inset 0 0 3px 3px #fdcf00;
    }
}

.photo{
    box-shadow: 
        0 0 50px 10px #eee4b9,
        inset 0 0 3px 1px #fdcf00;
    animation: 10s ease-in-out infinite glow-photo;
}


#photoTitle{
    width: 200px;
}