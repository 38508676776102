#treehousepic{
    width: 100%;
    padding: 20px;
}
#star{
    width: 100%;
    padding: 20px;
}
#light{
    width: 100%;
    padding: 20px;
}