

.title-text{
    flex-direction: column;
    align-items: center;
}

.lime-background{
    background-color: #1d1d1d;
}

.t_over:hover ~ .lime-background{
    background-color: #ace7aa;
}

.pageTitle{
    width: 250px;

    padding-bottom: 20px;
}

.pageLight{
    height: 100%;
}