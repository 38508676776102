.swovPage{

    font-family: "MontserratRegular";
     color: white;
     width: 100%;
     font-size: 18px;
     text-align: left;
     display: inline-flex;
     position: relative;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     margin-top: 50px;
 }


@media only screen and (max-width: 750px) {

  

}