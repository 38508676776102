ul{
    list-style: none;
}

a{
    color: white;
    font-style: 'montserrat';
    font-size: 18px;
}

.header{
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: inline-flex;
    z-index: 2;
}

.navLogo{
    position: relative;
}

.nav{
    display: inline-flex;
    align-items: baseline;
    box-shadow: 
        inset 0 0 50px 0px rgba(73, 73, 73, 0.55),
        0 0 20px 2px rgba(73, 73, 73, 0.6);
    border-radius: 10px;
    backdrop-filter: blur(5px);
}

.nav:hover{
    box-shadow: 
        inset 0 0 50px 0px rgba(73, 73, 73, 0.75),
        0 0 20px 2px rgba(73, 73, 73, 0.8);
}

.nav-item{
    padding: 25px;
    
}

.icon{
    width: 27px;
    height: 25px;
    
}

.subNav{
    display: none;
}

/* .nav-item:hover {
    background-color: yellowgreen;
} */

.nav-item:hover .subNav{
    display: block;
}


.subNav-item{
    padding: 5px;
}

.subNav-item:hover a{
    color: cyan;
    font-weight: 500;
}

.menu{
    display: none;
    padding: 25px;
    /* box-shadow: 
        inset 0 0 50px 0px rgba(73, 73, 73, 0.55),
        0 0 20px 2px rgba(73, 73, 73, 0.6);
    border-radius: 10px; */
}


#svLogo{
    padding: 15px;
    width: 75px;
    box-shadow: 
        inset 0 0 50px 0px rgba(73, 73, 73, 0.55),
        0 0 20px 2px rgba(73, 73, 73, 0.6);
    border-radius: 10px;
    backdrop-filter: blur(5px);
}

@media only screen and (max-width: 750px){

    .menu{
        display: block;
        /* background: #837272e0; */
    }
    .menu ul{
        transition: 0.4s;
    }

    #menuIcon{
        float: right;
        display: block;
    }

    #closeIcon{
        float: right;
        display: none;
    }


    .nav-item{
        display: none;
    }

    .mobile-nav-item{
        /* padding-top: 10px;
        padding-bottom: 10px; */
    }

    .mobile-menu{
        display:none;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100vh;
        
    }

    .mobile-icon{
        width: 32px;
        height: 30px;
    }

    .mobile-subNav-item{
        padding: 10px;
        padding-left: 30px;
        padding-right: 0px;
    }

    .mobile-subNav-item:hover a{
        color: cyan;
        font-weight: 500;
    }
}