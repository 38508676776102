.swovPage{

    font-family: "MontserratRegular";
     color: white;
     width: 100%;
     font-size: 18px;
     text-align: left;
     display: inline-flex;
     position: relative;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     margin-top: 50px;
     padding: 0% 20%;
     margin-bottom: 50px;
 }

 .introd{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
 }

 .subtitle{
    text-align: left;
    width: 100%;
    font-weight: 700;
 }

 .cart-image{
    width: 30%;
    padding: 30px;
 }


@media only screen and (max-width: 750px) {

  

}