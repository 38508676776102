@font-face
{
    font-family: "MontserratRegular";
    src: local(MontserratRegular), url("./musicInbox/Montserrat/Montserrat-Regular.ttf");
}

h2{
    font-size: 50px;
}

.sec a{
    font-size: 16px;
}

.musicInbox{

   font-family: "MontserratRegular";
    color: white;
    width: 100%;
    font-size: 18px;
    text-align: left;
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;
    padding: 0% 20%;
}

.sec{
    width: 85%;
}

.img-inbox{
    padding: 30px;
    margin: 40px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;

}

#spotifyData{
    background: #b0ffcbeb;
    box-shadow: 
        inset 0px 0px 40px 10px #58d562,
        0px 0px 40px 0px #a0faa8b5;
    border-radius: 20px;
    width: 70%;
}

#solution{
    width:50%;
}

#lofi{
    width: 60%;
}

#hifi{
    width: 70%;
}

#hifiProto{
    width: 80%;
}

@media only screen and (max-width: 750px){

    #spotifyData{
        width: 90%;
    }

    #solution{
        width: 90%;
    }

    #lofi{
        width: 90%;
    }
    
    #hifi{
        width: 90%;
    }

    #hifiProto{
        width: 90%;
    }
}