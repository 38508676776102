::-webkit-scrollbar{
    width: 5px;
}

::-webkit-scrollbar-thumb{
    background:-webkit-linear-gradient(#66ffb5, #58a0ff,#cb7eff);
    border-radius: 5px;
}



body{
    background-color: #080808;
}

p{
    font-style: 'montserrat';
    color: white;
    font-size: 24px;
    font-weight: 400;
}

b{
    font-weight: 700;
}

.light-weight{
    font-weight: 300;
}

.home{
    background-color: #080808;
    width: 100%;
    overflow-x: hidden;
}

.section{
    width: 100%;
    display: inline-flex;
    position: relative;
    justify-content: center;
}

.intro{
    height: 100vh;
    margin-bottom: 10px;
}

.projects{
    flex-direction: column;
    min-height: 60vh;
    margin-bottom: 2rem;
}

.flex{
    align-items: center;
}

.spaced{
    justify-content: space-evenly;
}

.text{
    flex-direction: column;
    align-items: flex-end;
    width: 40%;
}

.image{
    width: 50%;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#threeLights {
    animation: rotate 60s linear infinite;
    transform-origin: 50% 58%; /* This is the default, you can change these values */
    /* Some common transform-origin options:
       - 'center center' (default)
       - 'top left'
       - 'bottom right'
       - '100px 200px' (specific pixel values)
       - '50% 50%' (percentage values)
    */
    top: -50px
}

.lightbox {
    min-height: auto;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
}

.lightbar{
    background: white;
    border-radius: 10px;
    width: 10px;
    height: 100%;
    /* left: 10px; */
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
    margin-top: 100px;
}

.home-lightbar{
    background: white;
    border-radius: 10px;
    width: 10px;
    height: 100%;
    left: 10px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
    margin-top: 100px;
}

@keyframes glow-ui-ux {
    0% {
        box-shadow: 0px -10px 80px 20px #45a3ff,
        inset 0 0 3px 3px #45a3ff;
    }

    50% {
        box-shadow: 0 0 80px 35px #45a3ff,
        inset 0 0 3px 1px #45a3ff;
    }

    100% {
        box-shadow: 0px -10px 80px 20px #45a3ff,
        inset 0 0 3px 3px #45a3ff;
    }
}

@keyframes glow-green {
    0% {
        box-shadow: 0px -10px 80px 20px #00fbb8,
        inset 0 0 3px 3px #00fbb8;
    }

    50% {
        box-shadow: 0 0 80px 35px #00fbb8,
        inset 0 0 3px 1px #00fbb8;
    }

    100% {
        box-shadow: 0px -10px 80px 20px #00fbb8,
        inset 0 0 3px 3px #00fbb8;
    }
}

@keyframes glow-brand {
    0% {
        box-shadow: 0px -10px 80px 20px #29d772,
        inset 0 0 3px 3px #29d772;
    }

    50% {
        box-shadow: 0 0 80px 35px #29d772,
        inset 0 0 3px 1px #29d772;
    }

    100% {
        box-shadow: 0px -10px 80px 20px #29d772,
        inset 0 0 3px 3px #29d772;
    }
}

@keyframes glow-product {
    0% {
        box-shadow: 0px -10px 80px 20px #ff6b9c,
        inset 0 0 3px 3px #ff6b9c;
    }

    50% {
        box-shadow: 0 0 80px 35px #ff6b9c,
        inset 0 0 3px 1px #ff6b9c;
    }

    100% {
        box-shadow: 0px -10px 80px 20px #ff6b9c,
        inset 0 0 3px 3px #ff6b9c;
    }
}

@keyframes glow-photo {
    0% {
        box-shadow: 0px -10px 80px 20px #ffd700,
        inset 0 0 3px 3px #ffd700;
    }

    50% {
        box-shadow: 0 0 80px 35px #ffd700,
        inset 0 0 3px 1px #ffd700;
    }

    100% {
        box-shadow: 0px -10px 80px 20px #ffd700,
        inset 0 0 3px 3px #ffd700;
    }
}

@keyframes glow-art {
    0% {
        box-shadow: 0px -10px 80px 20px #ff4d4d,
        inset 0 0 3px 3px #ff4d4d;
    }

    50% {
        box-shadow: 0 0 80px 35px #ff4d4d,
        inset 0 0 3px 1px #ff4d4d;
    }

    100% {
        box-shadow: 0px -10px 80px 20px #ff4d4d,
        inset 0 0 3px 3px #ff4d4d;
    }
}

@keyframes glow-fashion {
    0% {
        box-shadow: 0px -10px 80px 20px #9932cc,
        inset 0 0 3px 3px #9932cc;
    }

    50% {
        box-shadow: 0 0 80px 35px #9932cc,
        inset 0 0 3px 1px #9932cc;
    }

    100% {
        box-shadow: 0px -10px 80px 20px #9932cc,
        inset 0 0 3px 3px #9932cc;
    }
}

@keyframes glow-illustration {
    0% {
        box-shadow: 0px -10px 80px 20px #ff8c00,
        inset 0 0 3px 3px #ff8c00;
    }

    50% {
        box-shadow: 0 0 80px 35px #ff8c00,
        inset 0 0 3px 1px #ff8c00;
    }

    100% {
        box-shadow: 0px -10px 80px 20px #ff8c00,
        inset 0 0 3px 3px #ff8c00;
    }
}

.green{
    box-shadow: 
        0 0 80px 20px #00fbb8,
        inset 0 0 3px 2px #00fbb8;
    animation: 10s ease-in-out infinite glow-green;
}

.ui-ux{
    box-shadow: 
        0 0 80px 20px #45a3ff,
        inset 0 0 3px 2px #45a3ff;
    animation: 10s ease-in-out infinite glow-blue;
}

.brand{
    box-shadow: 
        0 0 80px 20px #bb6cf9,
        inset 0 0 3px 2px #bb6cf9;
    animation: 10s ease-in-out infinite glow-purple;
}

.product{
    box-shadow: 
        0 0 80px 20px #ff6b9c,
        inset 0 0 3px 2px #ff6b9c;
    animation: 10s ease-in-out infinite glow-rose;
}

.photo{
    box-shadow: 
        0 0 80px 20px #ffd700,
        inset 0 0 3px 2px #ffd700;
    animation: 10s ease-in-out infinite glow-photo;
}

.art{
    box-shadow: 
        0 0 80px 20px #ff4d4d,
        inset 0 0 3px 2px #ff4d4d;
    animation: 10s ease-in-out infinite glow-art;
}

.fashion{
    box-shadow: 
        0 0 80px 20px #9932cc,
        inset 0 0 3px 2px #9932cc;
    animation: 10s ease-in-out infinite glow-fashion;
}

.illustration{
    box-shadow: 
        0 0 80px 20px #ff8c00,
        inset 0 0 3px 2px #ff8c00;
    animation: 10s ease-in-out infinite glow-illustration;
}

#projectsTitle {
    margin-bottom: 1rem;
}

.filter-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin: 1rem 0 3rem;
    flex-wrap: wrap;
    padding: 0 1rem;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.filter-button {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    padding: 0.6rem 1.2rem;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    outline: none;
    border-radius: 20px;
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    backdrop-filter: blur(5px);
}

.filter-button:hover {
    opacity: 0.9;
    background: rgba(255, 255, 255, 0.08);
}

.filter-button.active {
    opacity: 1;
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
}

.filter-button.green::after { background-color: #00fbb8; box-shadow: 0 0 8px #00fbb8; }
.filter-button.ui-ux::after { background-color: #45a3ff; box-shadow: 0 0 8px #45a3ff; }
.filter-button.brand::after { background-color: #29d772; box-shadow: 0 0 8px #29d772; }
.filter-button.product::after { background-color: #ff6b9c; box-shadow: 0 0 8px #ff6b9c; }
.filter-button.photo::after { background-color: #ffd700; box-shadow: 0 0 8px #ffd700; }
.filter-button.art::after { background-color: #ff4d4d; box-shadow: 0 0 8px #ff4d4d; }
.filter-button.fashion::after { background-color: #9932cc; box-shadow: 0 0 8px #9932cc; }
.filter-button.illustration::after { background-color: #ff8c00; box-shadow: 0 0 8px #ff8c00; }

.filter-button::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.filter-button:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-1px);
    border-color: rgba(255, 255, 255, 0.2);
}

.filter-button.active {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-1px);
    font-weight: 600;
}

.filter-button.green { color: rgba(0, 251, 184, 0.85); }
.filter-button.ui-ux { color: rgba(69, 163, 255, 0.85); }
.filter-button.brand { color: #29d772e0; }
.filter-button.product { color: rgba(255, 107, 156, 0.85); }
.filter-button.photo { color: rgba(255, 215, 0, 0.85); }
.filter-button.art { color: rgba(255, 77, 77, 0.85); }
.filter-button.fashion { color: rgba(153, 50, 204, 0.85); }
.filter-button.illustration { color: rgba(255, 140, 0, 0.85); }

.filter-button.ui-ux { border-color: rgba(69, 163, 255, 0.85); }
.filter-button.brand { border-color: #29d772e0; }
.filter-button.product { border-color: rgba(255, 107, 156, 0.85); }
.filter-button.rose { border-color: rgba(255, 107, 156, 0.85); }
.filter-button.photo { border-color: rgba(255, 215, 0, 0.85); }
.filter-button.art { border-color: rgba(255, 77, 77, 0.85); }
.filter-button.fashion { border-color: rgba(153, 50, 204, 0.85); }
.filter-button.illustration { border-color: rgba(255, 140, 0, 0.85); }

.filter-button.active.ui-ux { background: rgba(69, 163, 255, 1); border-color: rgba(69, 163, 255, 1); box-shadow: 0 0 35px rgba(69, 163, 255, 0.5); color: white; }
.filter-button.active.brand { background: #29d772; border-color: #29d772; box-shadow: 0 0 35px #29d77260; color: white; }
.filter-button.active.product { background: rgba(255, 107, 156, 1); border-color: rgba(255, 107, 156, 1); box-shadow: 0 0 35px rgba(255, 107, 156, 0.5); color: white; }
.filter-button.active.rose { background: rgba(255, 107, 156, 1); border-color: rgba(255, 107, 156, 1); box-shadow: 0 0 35px rgba(255, 107, 156, 0.5); color: white; }
.filter-button.active.photo { background: rgba(255, 215, 0, 1); border-color: rgba(255, 215, 0, 1); box-shadow: 0 0 35px rgba(255, 215, 0, 0.5); color: white; }
.filter-button.active.art { background: rgba(255, 77, 77, 1); border-color: rgba(255, 77, 77, 1); box-shadow: 0 0 35px rgba(255, 77, 77, 0.5); color: white; }
.filter-button.active.fashion { background: rgba(153, 50, 204, 1); border-color: rgba(153, 50, 204, 1); box-shadow: 0 0 35px rgba(153, 50, 204, 0.5); color: white; }
.filter-button.active.illustration { background: rgba(255, 140, 0, 1); border-color: rgba(255, 140, 0, 1); box-shadow: 0 0 35px rgba(255, 140, 0, 0.5); color: white; }

/* Remove all box shadows from card-text elements */
.card-text *,
.card-text *:hover,
.card-text *:active,
.card-text *:focus {
    box-shadow: none !important;
}

/* Tag styles */
.card-text span {
    padding: 0.35rem 0.75rem;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 0.75px;
    color: white;
    border: none;
}

.card-text {
    gap: 0.4rem;
    margin-top: 1rem;
}

/* Tag colors */
.card-text span.ui-ux { background: rgba(69, 163, 255, 0.85); }
.card-text span.brand { background: #29d772e0; }
.card-text span.product { background: rgba(255, 107, 156, 0.85); }
.card-text span.photo { background: rgba(255, 215, 0, 0.85); }
.card-text span.art { background: rgba(255, 77, 77, 0.85); }
.card-text span.fashion { background: rgba(153, 50, 204, 0.85); }
.card-text span.illustration { background: rgba(255, 140, 0, 0.85); }

.cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
    justify-items: center;
    width: calc(100% - 4rem);
    margin: 0 auto 2rem;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 1200px) {
    .cards {
        padding: 2.5rem 3rem;
        gap: 3rem;
    }
}

@media (max-width: 768px) {
    .cards {
        padding: 1rem;
        gap: 2rem;
        margin-bottom: 1.5rem;
    }
}

#projectsTitle {
    margin-bottom: 2rem;
}

#projectsTitle {
    /* margin-left: 110px; */
    margin-bottom: 2rem;
}

.filter-buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    margin: 0 0 1rem;
    width: 100%;
    padding-left: 10%;
}

.filter-button {
    background: transparent;
    border: 3px solid rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.7);
    padding: 0.45rem 1.1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
    font-weight: 700;
    font-family: 'montserrat', sans-serif;
    letter-spacing: 0.5px;
    box-shadow: none !important;
}

.filter-button.ui-ux { color: rgba(69, 163, 255, 0.85); }
.filter-button.brand { color: #29d772e0; }
.filter-button.product { color: rgba(255, 107, 156, 0.85); }
.filter-button.rose { color: rgba(255, 107, 156, 0.85); }
.filter-button.photo { color: rgba(255, 215, 0, 0.85); }
.filter-button.art { color: rgba(255, 77, 77, 0.85); }
.filter-button.fashion { color: rgba(153, 50, 204, 0.85); }
.filter-button.illustration { color: rgba(255, 140, 0, 0.85); }

.filter-button:hover {
    transform: translateY(-1px) scale(1.05);
    box-shadow: none !important;
}

.filter-button.active {
    transform: translateY(-1px) scale(1.05);
}



.filter-button.ui-ux:hover { border-color: rgba(69, 163, 255, 1); }
.filter-button.brand:hover { border-color: #29d772; }
.filter-button.product:hover { border-color: rgba(255, 107, 156, 1); }
.filter-button.rose:hover { border-color: rgba(255, 107, 156, 1); }
.filter-button.photo:hover { border-color: rgba(255, 215, 0, 1); }
.filter-button.art:hover { border-color: rgba(255, 77, 77, 1); }
.filter-button.fashion:hover { border-color: rgba(153, 50, 204, 1); }
.filter-button.illustration:hover { border-color: rgba(255, 140, 0, 1); }

.card{
    width: 100%;
    height: 100%;
    padding: 10px;
    transition: all 0.4s ease-out;
    perspective: 1000px;
    transform: rotateX(0deg) rotateY(0deg);
    transform-style: preserve-3d;
    border-radius: 10px;
    box-shadow: inset 0 0 5px 0px rgba(73, 73, 73, 0.55), 0 0 2px 0px rgba(73, 73, 73, 0.6);
}

.green-background{
    background-color: hsl(206deg 100% 73.3% / 2%);;
}

.tilt{
    position: relative;
    transition: all 0.4s ease-out;
    perspective: 1000px;
    width: 250px;
    height: 300px;
    margin: 10px;
}

.tilt:hover{
    transition: all 0.3s linear;
    /* transform: scale(1.1); */

}

.t_over{
    width: 33.333%;
    height: 33.333%;
    position: absolute;
    z-index: 2;
}

/* .t_over:hover ~ .green-background{
    background-color: #9acfaf;
} */

.t_over:nth-child(1){ left: 0%; top: 0%;}
.t_over:nth-child(2){ left: 33.333%; top: 0%;}
.t_over:nth-child(3){ left: 66.666%; top: 0%;}
.t_over:nth-child(4){ left: 0%; top: 33.333%;}
.t_over:nth-child(5){ left: 33.333%; top: 33.333%;}
.t_over:nth-child(6){ left: 66.666%; top: 33.333%;}
.t_over:nth-child(7){ left: 0%; top: 66.666%;}
.t_over:nth-child(8){ left: 33.333%; top: 66.666%;}
.t_over:nth-child(9){ left: 66.666%; top: 66.666%;} 

.t_over:nth-child(1):hover ~ .card{ transform: rotateX(20deg) rotateY(-20deg);}
.t_over:nth-child(2):hover ~ .card{ transform: rotateX(20deg) rotateY(0deg);}
.t_over:nth-child(3):hover ~ .card{ transform: rotateX(20deg) rotateY(20deg);}
.t_over:nth-child(4):hover ~ .card{ transform: rotateX(0deg) rotateY(-20deg);}
.t_over:nth-child(5):hover ~ .card{ transform: rotateX(0deg) rotateY(0deg);}
.t_over:nth-child(6):hover ~ .card{ transform: rotateX(0deg) rotateY(20deg);}
.t_over:nth-child(7):hover ~ .card{ transform: rotateX(-20deg) rotateY(-20deg);}
.t_over:nth-child(8):hover ~ .card{ transform: rotateX(-20deg) rotateY(0deg);}
.t_over:nth-child(9):hover ~ .card{ transform: rotateX(-20deg) rotateY(20deg);}

.t_over:nth-child(1):hover ~ .green-background{ box-shadow: inset 4px 4px 8px 2px #8dffc7, -2px -2px 8px 0px #8dffc7;}
.t_over:nth-child(2):hover ~ .green-background{ box-shadow: inset 0px 4px 8px 2px #8dffc7, 0px -2px 8px 0px #8dffc7;}
.t_over:nth-child(3):hover ~ .green-background{ box-shadow: inset -4px 4px 8px 2px #8dffc7, 2px -2px 8px 0px #8dffc7;}
.t_over:nth-child(4):hover ~ .green-background{ box-shadow: inset 4px 0px 8px 2px #8dffc7, 2px 0px 8px 0px #8dffc7;}
.t_over:nth-child(5):hover ~ .green-background{ box-shadow: inset 0px 0px 8px 0px #8dffc7, 0px 0px 4px 0px #8dffc7;}
.t_over:nth-child(6):hover ~ .green-background{ box-shadow: inset -4px 0px 8px 2px #8dffc7, 2px 0px 8px 0px #8dffc7;}
.t_over:nth-child(7):hover ~ .green-background{ box-shadow: inset 4px -4px 8px 2px #8dffc7, -2px 2px 8px 0px #8dffc7;}
.t_over:nth-child(8):hover ~ .green-background{ box-shadow: inset 0px -4px 8px 2px #8dffc7, 0px 2px 8px 0px #8dffc7;}
.t_over:nth-child(9):hover ~ .green-background{ box-shadow: inset -4px -4px 8px 2px #8dffc7, 2px 2px 8px 0px #8dffc7;}

.card-image{
    width: 100%;
    position: absolute;
    top: 10px;
    right: 10px;
    object-fit: cover;
    width: 230px;
    height: 280px;
    border-radius: 5px;
}

.card-title{
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    z-index: 2;
    transform: translateZ(40px);
}

.project-title{
    font-weight: 500;
}

.contrast{
    /* box-shadow: 
        inset 0 0 20px 20px rgba(29, 29, 29, 0.66),
        0 0 20px 2px rgba(29, 29, 29, 1);
    border-radius: 10px; */
    text-shadow: 0 0 5px black;
}

.project-date{
    font-weight: 700;
}

.card-text{
    position: absolute;
    padding: 0px;
    width: 80%;
    bottom: 25px;
    left: 25px;
    transform: translateZ(40px);
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-end;
    justify-content: flex-start;
    align-items: center;
}

.product-design{
    background: #00fbb899;
    backdrop-filter: blur(5px);
    padding: 2px 10px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
    margin-right: 5px;
}

.brand-design{
    background: #29d77299;
    backdrop-filter: blur(5px);
    padding: 2px 10px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
    margin-right: 5px;
}

.ux-design{
    background: #1ee1ff99;
    backdrop-filter: blur(5px);
    padding: 2px 10px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
    margin-right: 5px;
}

.illustration-design{
    background: #ff1e8099;
    backdrop-filter: blur(5px);
    padding: 2px 10px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
    margin-right: 5px;
}

.greenText{
    color: #8dffc7;
}

.greenTextHover:hover{
    color: #8dffc7;
    font-weight: 700;
}

.blueText{
    color: #93e1fd;
}

.blueTextHover:hover{
    color: #93e1fd;
    font-weight: 700;
}


.purpleText{
    color: #dfb9f2;
}

.purpleTextHover:hover{
    color: #dfb9f2;
    font-weight: 700;
}

.about{
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-right: 10vw;
}


#nameTitle{
    max-width: 525px;
    width: 40vw;
    position: relative;
}

#threeLights{
    width: 100%;
    position: relative;
}

#projectsTitle{
    width: 400px;
    padding-left: 8vw;
}

#projectLight{
    height: 100%;
}

#aboutTitle{
    width: 250px;
    padding-left: 8vw;
}

#designLight{
    height: 100%;
}

#contactTitle{
    width: 270px;
    padding-left: 8vw;
    padding-bottom: 2vw;
}

#fashionLight{
    height: 100%;
}

.contact{
    height: 60%;
}

@media only screen and (max-width: 750px) {

    body{
        width: 100%;
    }

    .intro{
        margin-bottom: 100px;
        margin-top: 50px;
    }

    .spaced{
        flex-direction: column;
        margin-top: 100px;
    }

    .text{
        align-items: center;
        width: 85%;
    }

    .image{
        width: 90%;
    }

    .design .lightbox .cards{
        padding-left: 15%;
    }

    .cards::-webkit-scrollbar{
        width: 0px;
    }

    .lightbox{
        height:inherit
    }

    .lightbar{
        height: 90vh;
        margin: 0px 18px;
        position: fixed;
        top: 70px;
    }

    .rightbar{
        right: 10px;
        display: none;
    }

    .leftbar{
        left: 10px;
    }

    .tilt{
        height: 200px;
        width: 240px;
    }
    
    .card-image{
        height: 180px;
        width: 220px;
    }

    .card-text{
        font-size: 12px;
        display: none;
    }

    .projects{
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .project-title{
        font-weight: 500;
    }
    

    .about{
        padding-right: 2vw;
    }

    #nameTitle{
        width: 450px;
    }

    #projectsTitle{
        padding-bottom: 8vw;
    }

    #aboutTitle{
        padding-bottom: 30vw;
        padding-top: 15vw;
    }

    #projectLight{
        height: 120vh;
    }

    #designLight{
        height: 120vh;
    }


}